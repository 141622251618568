import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Filter from '../components/Filter'
import Cloud from '../components/Cloud'
import { getNames } from '../features/name/nameSlice'
import { getFavourites } from '../features/favourite/favouriteSlice'

function Search () {

    const user = useSelector((state) => state.auth)
    const {coordinates, isError, isLoading, isSuccess, message, names} = useSelector((state) => state.name)
    const favouriteNames = useSelector((state) => state.favourite.favouriteNames)

    const dispatch = useDispatch()

    useEffect(() => {

        if(isError) {
            console.log(message)
        }

        // if there is a user, check for favourites first, otherwise just get the names
        if(user) {
            dispatch(getFavourites()).then(()=>{
                dispatch(getNames())
            })
        } else {
            dispatch(getNames())
        }

  
    }, [])

    return (
        <>
            <div className="main-container sky">
                {isLoading ? 
                    <div id="heart" >
                        <img className="bottom" src="/images/heart.png" alt="heart loading"></img>
                    </div> : 
                <>
                <Filter></Filter>
                 {coordinates ?
                    <div className="inner-sky">
                    {names.map((batch, i) => (
                        <div className="sky-wrapper" key={i}>
                            <div className="sky-container">
                                {batch.map((name, j) => (
                                    <Cloud 
                                        key={j}
                                        name={name.name}
                                        nameGender={name.gender}
                                        nameId={name.id}
                                        favouriteNames={favouriteNames}
                                        favouriteId={favouriteNames[name.name]}
                                        left={coordinates[i][j]['topLeft']['x']}
                                        top={coordinates[i][j]['topLeft']['y']}
                                        active={Object.keys(favouriteNames).includes(name.name)}
                                    ></Cloud>
                                ))}
                            </div>
                        </div>
                    ))}
                    </div>
                : <div></div>}
                </>
                }
               
            </div>
        </>
    )
}

export default Search 