import axios from 'axios'

const API_URL = '/api/names/'


// Get all names
const getNames = async (data) => {
  const config = {
      data: data
  }

  const response = await axios.post(API_URL, config)
  return response.data
}


const nameService = {
  getNames,
}

export default nameService