import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import MyFavourites from './pages/MyFavourites';
import Search from './pages/Search';
import Header from './components/Header';
import Bar from './components/Bar';
import Menu from './components/Menu';
import Imprint from './pages/Imprint';
import NotFound from './pages/NotFound';
import './index.css'

function App() {
    return (
        <Router>
            
              <Header></Header>
              
              <Routes>
                  <Route path='/' element={<Search/>}></Route>
                  <Route path='/myfavourites' element={<MyFavourites/>}></Route>
                  <Route path='/imprint' element={<Imprint/>}></Route>
                  <Route path='*' element={<NotFound/>}></Route>
              </Routes>

              <Bar></Bar>

              <Menu></Menu>
        </Router>
    );
}

export default App;
