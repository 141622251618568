import { useSelector, useDispatch } from 'react-redux'
import { setMenuY, setMenuZIndex } from '../features/menu/menuSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons'



function Header() {


    const menu = useSelector((state) => state.menu)
    const dispatch = useDispatch()


    const clickHandle = () => {
        if(menu.menuY === 150) {
            dispatch(setMenuY('100%'))
            setTimeout(() => {
                dispatch(setMenuZIndex(-1))
            }, 400);
        } else {
            dispatch(setMenuZIndex(30))
            setTimeout(() => {
                dispatch(setMenuY(150))
            }, 100); 
        }
    }


    return (
        <header className="header main-grid shadow-md">
            <img className="header-logo" onClick={() =>  window.location.href='/'} alt="find your babyname logo" src="/images/c22-02.png"></img>

            {menu.menuY === 150 ? <div onClick={clickHandle}><FontAwesomeIcon icon={faXmark} className="header-icon header-icon-close"/></div>
                              : <div onClick={clickHandle}><FontAwesomeIcon icon={faBars} className="header-icon header-icon-burger"/></div>}
        </header>
    )
}

export default Header