import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import favouriteService from './favouriteService'


const initialState = {
    favourites: [],
    favouriteNames: {},
    isInitialLoading: true,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// set name a favourite
export const createFavourite = createAsyncThunk('favourite/set', async(favouriteData, thunkAPI) => { 
    try {
        const token = thunkAPI.getState().auth.user?.token
        return await favouriteService.setFavourite(favouriteData, token) 
      }
      // catch error if the token is not valid anymore, to create a new one 
      catch (error) {
        if (error.response.status === 401) {
            return thunkAPI.rejectWithValue(401)
        } else {
            const message =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString()
            return thunkAPI.rejectWithValue(message)
        }
      }
})

// Get favourite Names
export const getFavourites = createAsyncThunk('favourite/getAll', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await favouriteService.getFavourites(token)
    } catch (error) {
        const message = (
            error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

// Delete Favourite Name
export const deleteFavourite = createAsyncThunk('favourite/delete', async(id, thunkAPI) =>{
    try {
        const token = thunkAPI.getState().auth.user.token
        return await favouriteService.deleteFavourite(id, token)
    } catch (error) {
        const message = (
            error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})

export const updateFavourite = createAsyncThunk('favourite/update', async({ favouriteId, rating }, thunkAPI) => {
    try {
        const token = thunkAPI.getState().auth.user.token
        return await favouriteService.updateFavourite({ favouriteId, rating }, token)
    } catch (error) {
        const message = (
            error.response &&
            error.response.data &&
            error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
})


const extractFavouriteNames = (favourites) => {
    var names = {}
        favourites.forEach(function(favourite, _) {
            if(favourite.customName!==null) {
                names[favourite.customName] = favourite.favouriteId
            } else {
                names[favourite.name.name] = favourite.favouriteId
            }
        })
    return names
} 


export const favouriteSlice = createSlice({
    name: 'favourite',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(getFavourites.pending, (state) => {
            state.isLoading = true
        })
        .addCase(getFavourites.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isInitialLoading = false
            state.favourites = action.payload
            state.favouriteNames = extractFavouriteNames(action.payload)
        })
        .addCase(getFavourites.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.isInitialLoading = false
            state.message = action.payload
        })
        .addCase(createFavourite.pending, (state) => {
            state.isLoading = true
        })
        .addCase(createFavourite.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.favourites.push(action.payload)
            state.favouriteNames = extractFavouriteNames(state.favourites)
        })
        .addCase(createFavourite.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(deleteFavourite.pending, (state) => {
            state.isLoading = true
        })
        .addCase(deleteFavourite.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            // console.log(state.favourites)
            state.favourites = state.favourites.filter(
                (favourite) => favourite.favouriteId !== action.payload.id
            )            
            state.favouriteNames = extractFavouriteNames(state.favourites)
        })
        .addCase(deleteFavourite.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
        .addCase(updateFavourite.pending, (state) => {
            state.isLoading = true
        })
        .addCase(updateFavourite.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            // state.message = action.payload
            state.favourites = action.payload
            state.favouriteNames = extractFavouriteNames(action.payload)
        })
        .addCase(updateFavourite.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
        })
    }
})

export const {reset} = favouriteSlice.actions
export default favouriteSlice.reducer