import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { deleteFavourite, updateFavourite } from '../features/favourite/favouriteSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Rating from 'react-rating'
import { faHeart, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faHeart as emptyHeart} from '@fortawesome/free-regular-svg-icons';
import {faSquareMinus} from '@fortawesome/free-regular-svg-icons'
import { useState } from 'react'


// import { deleteFavourite } from '../features/favourite/favouriteSlice'

function FavouriteItem({ element, favourite }) {
  const dispatch = useDispatch()


  function ratingCount(rating, favouriteId) {
    dispatch(updateFavourite({ favouriteId, rating }))
  }


  function handleClick(favouriteId) {
    dispatch(deleteFavourite(favouriteId))
  }

  const colorDict = {
    'm': 'male-color',
    'f': 'female-color'
  }
  

  return (
    <div>
        <div className='favourites-list'>
            <div className={"favourite-name-text min-w-0 " + (favourite.customGender? colorDict[favourite.customGender] : colorDict[favourite.name.gender])}><span className='truncate'>{favourite.customGender ? favourite.customName : favourite.name.name}</span></div>
            <div className="flex justify-center items-center leading-4">

                <Rating
                    emptySymbol = {<FontAwesomeIcon icon={emptyHeart} className="rating-icon rating-icon-empty"/>}
                    fullSymbol = {<FontAwesomeIcon icon={faHeart} className="rating-icon rating-icon-full"/>}
                    onClick={(rating) => ratingCount(rating, favourite.favouriteId)}
                    initialRating={favourite.rating}
                />
                
            </div>
            <div className="delete-btn">
                <button className="delete-favourite-btn" onClick={() => handleClick(favourite.favouriteId)}>&times;</button>
            </div>
        </div>
    </div>
  )
}

export default FavouriteItem