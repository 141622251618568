import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import nameService from './nameService'

const initialState = {
    names: [],
    coordinates: [],
    filter: {},
    isError: false,
    isSuccess: false,
    isLoading: true,
    message: ''
}

// pull the names from the database
export const getNames = createAsyncThunk('api/names', async(data, thunkAPI) => {
    try {
        return await nameService.getNames(data)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
        return thunkAPI.rejectWithValue(message)
      }
})

export const nameSlice = createSlice({
    name: 'name',
    initialState,
    reducers: {
        reset: (state) => initialState,
        setFilter(state, filter) {
            state.filter = filter.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNames.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNames.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.names = action.payload['names']
                state.coordinates = action.payload['coordinates']
            })
            .addCase(getNames.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    }
})

export const { reset,  setFilter } = nameSlice.actions
export default nameSlice.reducer