import { createSlice } from '@reduxjs/toolkit'

const currentPageSlice = createSlice({
    name: 'currentPage',
    initialState: {
        currentPage: '/',
    },
    reducers: {
        setCurrentPage(state, currentPage) {
          state.currentPage = currentPage.payload;
        }
    }
});

export const { setCurrentPage } = currentPageSlice.actions;
export default currentPageSlice.reducer

