import { useSelector, useDispatch } from 'react-redux'
import { register } from '../features/auth/authSlice'
import { createFavourite, deleteFavourite } from '../features/favourite/favouriteSlice'
import { useState } from 'react'




function Cloud({top, left, favouriteNames, favouriteId, name, nameGender, nameId, active}) {

    const dispatch = useDispatch()

    const clickCloud = () => {
        // the heart is active and the user has clicked.
        // the user does not like the name anymore. remove it from favoruties
        if (active) {
            dispatch(deleteFavourite(favouriteId))
        } else {

            // the user liked the name. add it to the favourites
            dispatch(createFavourite({'nameId':nameId, 'name':name})).then(
                (res) => {
                    // if token is invalid, create a new token by registering a new user first
                    if (res.payload === 401) {
                        dispatch(register())
                        .then(()=>
                            // then try again to set the favourite
                            dispatch(createFavourite({'nameId':nameId, 'name':name}))

                        )
                    }
                }
            )
        }
    }


    return (
        <div className="sky-icon-container" style={{top:top, left:left}} onClick={clickCloud}>
            <img className={"sky-icon-cloud " + (active ? "hidden" : "visible")} src="/images/cloud.png" alt="cloud name"></img>
            <img className={"sky-icon-heart " + (active ? "visible" : "hidden")} src="/images/heart.png" alt="heart name"></img>
            <p className={"sky-icon-text " + (nameGender === 'm' ? "male-color" : "female-color") + (active ? " sky-icon-btn-active" : "")}>
                {name}
            </p>
            {/* <button name-id={nameId} name={name} 
                    className={"sky-icon-btn"} favourite-id={favouriteId}
                    ></button> */}
        </div>
    )
}


export default Cloud