import React, { Component } from 'react'

export class FavouritePrint extends Component {
    
    render() {
        return (
        <div className="print-favourites-container" style={{padding: '80px 0 0 70px'}}>
            <img style={{width: '180px', marginBottom: '40px'}} alt="print favourite baby names" src="/images/logo.png"></img>
            
            {this.props.names.map(function(name, i) {
                return (                
                    <div key={i} style={{display: 'flex', height: '45px', alignItems: 'center'}}>
                        <p style={{color: (name.customGender ? 
                                            {'m':'#68BBF3', 
                                             'f':'#e6aacf'}[name.customGender]:
                                            {'m':'#68BBF3', 
                                             'f':'#e6aacf'}[name.name.gender]),
                                    fontWeight: 500,
                                    margin: 0, width: '170px'}}>
                            {name.customName ? 
                                name.customName : 
                                name.name.name}
                        </p>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                            {[...Array(name.rating)].map((_, index) => ( 
                                <img key={index} alt="rating heart name" style={{width: '15px', marginRight: '5px'}} src="/images/heart.png"></img>
                            ))}
                        </div>
                    </div>
            )})}
        </div>
        )
    }
}

export default FavouritePrint