import { Link } from 'react-router-dom'

function NotFound() {
    return (
        <div className='main-container sky'>
            <div className='not-found-container'>
                <img id="cloud1" src="/images/cloud.png"></img>
                <img id="cloud2" src="/images/cloud.png"></img>
                <h1 className="not-found-title">404</h1>
                <h2 className="not-found-subtitle">Sorry, die Seite ist unbekannt.</h2>
                <Link className='back-to-search-btn' to="/">Zurück zur Suche</Link>
                <img id="cloud3" src="/images/cloud.png"></img>
                <img id="cloud4" src="/images/cloud.png"></img>
            </div>
        </div>
    )
}

export default NotFound