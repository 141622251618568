import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { setFilter, getNames } from '../features/name/nameSlice'

function Filter() {

    const filter = useSelector((state) => state.name.filter)

    const dispatch = useDispatch()
    const clickHandle = (dimension, action, payload) => {
        var newFilter = { ...filter }

        if(!(dimension in newFilter) && action==='add') {
            newFilter[dimension] = []
        }
        if (action==='add') {
          newFilter[dimension] = [...newFilter[dimension], payload]
        }

        if(action==='remove') {
            newFilter[dimension] = newFilter[dimension].filter(item => item !== payload)
        }
        if(newFilter[dimension].length === 0) {
            delete newFilter[dimension]
        }

        dispatch(setFilter(newFilter))
        dispatch(getNames(newFilter))
    }


    return (
        <div className="sky-filter">
            <FontAwesomeIcon className="filter-icon" icon={faFilter}/>
            <div className="vertical-line"></div>
            {filter?.gender?.includes('f') ?  
                <button className="sky-filter-btn sky-filter-btn-active" onClick={()=>clickHandle('gender', 'remove', 'f')}>Mädchen</button> :
                <button className="sky-filter-btn" onClick={()=>clickHandle('gender', 'add', 'f')}>Mädchen</button>
            }
            {filter?.gender?.includes('m') ? 
                <button className="sky-filter-btn sky-filter-btn-active" onClick={()=>clickHandle('gender', 'remove', 'm')}>Jungen</button> :
                <button className="sky-filter-btn" onClick={()=>clickHandle('gender', 'add', 'm')}>Jungen</button>
            }
            
        </div>
    )
}

export default Filter