import axios from "axios";

const API_URL = '/api/favourite'

// set a favourite name
const setFavourite = async (favouriteData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, favouriteData, config) //, user 
    return response.data
}

// Create new Favourite Name
const createFavourite = async (favouriteData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, favouriteData, config)

    return response.data

}

// Get Favourite Names
const getFavourites = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL, config)
    return response.data

}

// Delete Favourite Name
const deleteFavourite = async (favouriteId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.delete(API_URL + '/' + favouriteId, config)
    return response.data
}


// Update Favourite Name
const updateFavourite = async ({ favouriteId, rating }, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    const response = await axios.put(API_URL + '/' + favouriteId, { rating }, config)
    return response.data
}

const favouriteService = {
    createFavourite,
    getFavourites,
    setFavourite,
    deleteFavourite,
    updateFavourite
}

export default favouriteService