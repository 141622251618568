import { createSlice } from '@reduxjs/toolkit'

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        menuY: '100%',
        zIndex: -1
    },
    reducers: {
        setMenuY(state, menuY) {
          state.menuY = menuY.payload;
        },
        setMenuZIndex(state, zIndex) {
            state.zIndex = zIndex.payload;
        }
    }
});

export const { setMenuY, setMenuZIndex } = menuSlice.actions;
export default menuSlice.reducer

