import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentPage } from '../features/currentPage/currentPageSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faHeart } from '@fortawesome/free-solid-svg-icons';
import { getFavourites } from '../features/favourite/favouriteSlice'



function Bar() {

    const favourites = useSelector((favourites) => favourites.favourite.favourites)

    useEffect(() => {
        // first, get already chosen favourites
        dispatch(getFavourites())
    }, [])

    const navigate = useNavigate();
    const location = useLocation().pathname;
    
    const dispatch = useDispatch();

    function handleClick(path) {
        navigate(path);
        dispatch(setCurrentPage(path))
    }

    return (
        <div className="bar main-grid">

            <div className='icon-wrapper'>
                <button className={'icon-container ' + (location === '/' && 'dark')} onClick={() => handleClick('/')}>
                    <FontAwesomeIcon icon={faHouse} className="bar-icon bar-icon-search"/>
                    <p className='bar-title'>Suche</p>
                </button>
            </div>

            <div className='icon-wrapper'>
                <button className={"icon-container "+ (location === '/myfavourites' && 'dark')} onClick={() => handleClick('/myfavourites')}>
                    <FontAwesomeIcon icon={faHeart} className='bar-icon bar-icon-favs bar-icon-heart' />
                    <div className="circle-container">
                        <div className={'circle ' + (favourites.length === 0 && 'no-favs')}>
                            <div className="circle-text">{favourites.length}</div>
                        </div>
                    </div>
                    <p className='bar-title'>Favouriten</p>
                </button>
            </div>

            <div className={'bar-icon-imprint ' + (location === "/imprint"? "dark" : "")} onClick={() => handleClick('/imprint')}>
                <p className='bar-title'>Impressum</p>
            </div>
        </div>
    )
}


export default Bar