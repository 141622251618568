import { configureStore } from '@reduxjs/toolkit';
import authReducer  from '../features/auth/authSlice';
import nameReducer from '../features/name/nameSlice';
import favouriteReducer from '../features/favourite/favouriteSlice'
import menuReducer from '../features/menu/menuSlice';
import currentPageReducer from '../features/currentPage/currentPageSlice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        name: nameReducer,
        favourite: favouriteReducer,
        menu: menuReducer,
        currentPage: currentPageReducer
    },
});
